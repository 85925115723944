@import 'partials/mixins';
@import 'partials/variables';
@import 'partials/media-query';

$primary-color:#dca44d;
$secondary-color:#515b78;

/*=== primary color ===*/
h3 a:hover,
a,
.tabCommon .nav li.active a,
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li.active a,
.navbar-default .navbar-nav > li.active > a,
.navbar-default .navbar-nav > li.active > a:hover,
.navbar-default .navbar-nav > li.active a:focus,
.navbar-nav li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a:hover,
.navbar-nav li.dropdown.singleDrop .dropdown-menu li a:hover,
.slide-inner1 .h3,
.main-slider .common-inner .h3,
.footerTop-inner .list-inline li > a:hover,
.footerTop-inner .list-inline li.active a,
.footerInfo h4,
.footerBottom-inner .media-body a,
.footerBottom-inner .footerInfo a:hover,
.copyRight .list-inline li a:hover,
.copyRightText p a:hover,
.banner-bottom-inner .media-left i,
.free-request i,
.gallery-img .overlay .overlayInfo i,
.box .box-inner:hover i,
.countUpSection.paralax .icon i,
.teamContent .list-inline li a:hover,
.teamContent:hover h3 a,
.teamContent.teamCol h3:hover a,
.commentContent i,
.commentContent h3,
.gridView > .caption h2 a:hover,
.download, .download:hover,
.sideNav li a:hover,
.testimonials i,
.testimonials h5,
.paralax-info i,
.position .position-box h3 a:hover,
.contact-info .box-inner i,
.contact-info .box-inner a:hover,
.isotopeFilters ul.filter > li.active a,
.profile-contact span i,
.profile-detail h4 span,
.more:hover,
.thumbnail blockquote a,
.media-list.comment-list .media-body a,
.errorMsg h1,
.testimonials h5 a{
  color: $primary-color;
}
.btn-primary, .btn-primary:hover,
.btn-primary:focus, .btn-primary.active, .btn-primary:active,
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary.active, .btn-secondary:active,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.backToTop,
.btn-theme, .btn-theme:focus, .btn-theme.active, .btn-theme:active, .btn-theme:hover,
.sectionTitle h2:before, .sectionTitle h2:after,
.customModal .modal-content button.close,
.sticker,
.pagerArea > .pager li a,
.pagination > li > a:hover, .pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus,
.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus,
.social-icon li a:hover,
.navbar-default .navbar-toggle,
.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover,
.searchBox a i,
.main-slider .slide-inner3 .h3,
.footerInfo .input-group .input-group-addon:hover,
.progress-bar-container.color .progress-bar,
.box .box-inner .btn:hover,
.commentSection .owl-theme .owl-dots .owl-dot.active span,
.commentSection .owl-theme .owl-dots .owl-dot:hover span ,
.lightSection,
.isotopeSelector .overlay .fancybox-pop i,
.priceUper:hover{
  background-color: $primary-color;
}
.btn-theme, .btn-theme:focus, .btn-theme.active, .btn-theme:active, .btn-theme:hover,
.formField .form-control:focus,
.pagination > li > a:hover, .pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus,
.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus,
.navbar-default .navbar-toggle,
.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover,
.footerInfo .form-control:focus,
.footerInfo .input-group .input-group-addon:hover,
.teamContent:hover .teamInfo,
.sidebar .input-group .form-control:focus,
.isotopeFilters ul.filter > li.active a{
  border-color: $primary-color;
}
.Tab-style2 .tabCommon .nav li.active a,
.main-slider .slide-inner2 {
  border-top-color: $primary-color;
}
@include tablet {
  .navbar-default .navbar-nav > li > a:hover,
  .navbar-default .navbar-nav > li > a:focus,
  .navbar-default .navbar-nav > li.active > a,
  .navbar-default .navbar-nav > li.active > a:hover,
  .navbar-default .navbar-nav > li.active a:focus,
  .navbar-default .navbar-nav > li.open > a,
  .navbar-default .navbar-nav > li.open > a:hover,
  .navbar-default .navbar-nav > li.open > a:focus{
    background-color: $primary-color;
  }
  .navbar-default .navbar-nav > li > a:hover,
  .navbar-default .navbar-nav > li > a:focus,
  .navbar-default .navbar-nav > li.open > a {
    border-bottom-color: $primary-color;
  }
  .nav-wrapper .navbar-nav li.open .dropdown-menu,
  .nav-wrapper .navbar-nav li.dropdown .dropdown-menu{
    border-top-color: $primary-color;
  }
}

/*=== secondary color ===*/
.btn-secondary ,
.btn-dark, .btn-dark:focus, .btn-dark.active, .btn-dark:active, .btn-dark:hover,
.top-info-bar,
.block > h3,
.priceUper,
.count-down #simple_timer .table-cell .tab-val{
  background-color: $secondary-color;
}
.btn-dark, .btn-dark:focus, .btn-dark.active, .btn-dark:active, .btn-dark:hover{
  border-color: $secondary-color;
}
